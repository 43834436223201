import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_ROOT_API;

class EmailService {

    sendCotizacion(data) { 

        
        console.log(API_URL + '/' + data.id + '/' + data.email );


        return axios.post(
                API_URL + '/api/sendmail',
                data,
                { headers: authHeader(), "Content-Type": "multipart/form-data", })
                .then(response => {

                    if(response.status == 200){
                        return response.data;
                    } 

                }).catch(error => {
                    
                    return error.response;

            })

    }

        
}


export default new EmailService();